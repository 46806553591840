import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import MainPages from './pages/mainPages';
import SecondPages from './pages/secondPages';
import './app.css';
import Authorization from './pages/authorization/authorization';
import Verification from './pages/verification/verification';
import Parking from './pages/parking/parking';
import Privacy from './pages/doc/privacy';
import { useParkByIdQueryNewBase } from './api/apiNewBase';


function CheckAuthAndRedirect() {
  const navigate = useNavigate();
  const { data: parkingData, refetch } = useParkByIdQueryNewBase(157749);
  const token = localStorage.getItem('UserToken');
 
  useEffect( () => {
  if (token) {
    refetch()
    navigate('/parking/157749/park', { state: { parkingData } });
    }
  },[token, parkingData]);
 
  return null;
 }

function App() {
 
  return (
    <Router>
      <CheckAuthAndRedirect />
      <Routes>
        <Route path="/" element={
          <>
            <MainPages />
          </>
        } />
 
        <Route path="/parking/second" element={
          <>
            <SecondPages />
          </>
        } />
 
        <Route path="/parking/auth" element={
          <>
            <Authorization />
          </>
        } />       
 
        <Route path="/parking/:id/verify" element={
          <>
            <Verification />
          </>
        } /> 
 
        <Route path="/parking/:id/park" element={
          <>
            <Parking />
          </>
        } /> 
        <Route path="/privacy" element={<Privacy />} />      
      </Routes>
    </Router>
  );
 }
 

export default App;
