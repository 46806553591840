import React from 'react';
import './privacy.css'; 

function Privacy() {
  return (
    <div className="privacy-container">
      <h1>Политика конфиденциальности</h1>

      <p>
        Настоящая Политика конфиденциальности (далее — Политика) определяет порядок обработки и защиты персональных данных пользователей (далее — Пользователь), которые могут быть получены ООО Два орла (далее — Компания) при использовании мобильного приложения "НаМесте" (далее — Приложение).
      </p>

      <h2>1. Общие положения</h2>
      <p>
        <strong>1.1.</strong> Использование Приложения означает согласие Пользователя с настоящей Политикой и условиями обработки его персональных данных.
      </p>
      <p>
        <strong>1.2.</strong> Настоящая Политика разработана в соответствии с законодательством Российской Федерации в области персональных данных.
      </p>

      <h2>2. Персональные данные Пользователей</h2>
      <p>
        <strong>2.1.</strong> Компания может собирать следующие персональные данные Пользователя:
      </p>
      <ul>
        <li>Фамилия, имя, отчество;</li>
        <li>Контактный телефон;</li>
        <li>Адрес электронной почты;</li>
        <li>Данные геолокации;</li>
        <li>Иные данные, которые Пользователь предоставляет самостоятельно.</li>
      </ul>

      <h2>3. Цели обработки персональных данных</h2>
      <p>
        <strong>3.1.</strong> Персональные данные Пользователя обрабатываются в целях:
      </p>
      <ul>
        <li>Предоставления доступа к функционалу Приложения;</li>
        <li>Связи с Пользователем, включая направление уведомлений и запросов;</li>
        <li>Улучшения качества Приложения и разработки новых сервисов;</li>
        <li>Проведения статистических и иных исследований на основе обезличенных данных.</li>
      </ul>

      <h2>4. Условия обработки персональных данных</h2>
      <p>
        <strong>4.1.</strong> Компания обеспечивает сохранность персональных данных и принимает все возможные меры для предотвращения доступа к персональным данным неуполномоченных лиц.
      </p>
      <p>
        <strong>4.2.</strong> Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, предусмотренных законодательством Российской Федерации.
      </p>

      <h2>5. Обязательства сторон</h2>
      <p>
        <strong>5.1.</strong> Пользователь обязуется:
      </p>
      <ul>
        <li>Предоставить корректную и правдивую информацию о себе;</li>
        <li>Обновлять и дополнять предоставленную информацию в случае ее изменения;</li>
        <li>Принимать меры по защите доступа к своим конфиденциальным данным.</li>
      </ul>
      <p>
        <strong>5.2.</strong> Компания обязуется:
      </p>
      <ul>
        <li>Использовать полученную информацию исключительно для целей, указанных в настоящей Политике;</li>
        <li>Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя;</li>
        <li>Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя.</li>
      </ul>

      <h2>6. Прочие условия</h2>
      <p>
        <strong>6.1.</strong> Компания имеет право вносить изменения в настоящую Политику без согласия Пользователя. Новая редакция Политики вступает в силу с момента ее размещения в Приложении.
      </p>
      <p>
        <strong>6.2.</strong> Все вопросы и предложения по данной Политике следует направлять на адрес электронной почты <a href="mailto:help@nameste.pro">help@nameste.pro</a>.
      </p>
      <p>
        <strong>6.3.</strong> К настоящей Политике и отношениям между Пользователем и Компанией применяется законодательство Российской Федерации.
      </p>

      <p>
        Нажимая кнопку "Авторизоваться", я подтверждаю, что ознакомлен(а) с условиями <strong>Политики конфиденциальности</strong> и принимаю их.
      </p>
    </div>
  );
}

export default Privacy;
